import React from "react";
import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import CtaBanner from "../components/CtaBanner";
import { graphql } from "gatsby";
import { centeredBlock, legalTextStyle } from "../style/shared";
import MailLink from "../components/MailLink";

const additionalSpacing = css`
    padding-top: 12px;
    padding-bottom: 84px;
`;

export default ({ data }) => (
    <React.Fragment>
        <Helmet>
            <title>Oliver Schmitt - Datenschutzerklärung</title>
            <meta name="robots" content="noindex nofollow" />
        </Helmet>
        <div css={[centeredBlock, additionalSpacing, legalTextStyle]}>
            <h1>Datenschutzerklärung</h1>
            <h2>1. Name und Kontaktdaten des Verantwortlichen</h2>
            <p>
                Diese Datenschutzerklärung informiert über die Verarbeitung
                personenbezogener Daten auf der Kanzleiwebseite von:
            </p>
            <p>Verantwortlicher:</p>
            <p>
                Oliver Schmitt Steuerberatungesellschaft mbH
                <br />
                Kasseler Stra&szlig;e 30
                <br />
                61118 Bad Vilbel
            </p>

            <p>
                Telefon: +49 (6101) 800660
                <br />
                Telefax: +49 (6101) 800670
                <br />
                E-Mail: <MailLink to="info" />
            </p>
            <h2>
                2. Umfang und Zweck der Verarbeitung personenbezogener Daten
            </h2>
            <h3>2.1 Aufruf der Webseite</h3>
            <p>
                Beim Aufruf dieser Webseite{" "}
                <a href="https://steuerschmitt.com">
                    https://steuerschmitt.com
                </a>{" "}
                werden durch den Internet-Browser, den der Besucher verwendet,
                automatisch Daten an den Server dieser Webseite gesendet und
                zeitlich begrenzt in einer Protokolldatei (Logfile) gespeichert.
                Bis zur automatischen Löschung werden nachstehende Daten ohne
                weitere Eingabe des Besuchers gespeichert:
            </p>
            <ul>
                <li>IP-Adresse des Endgeräts des Besuchers,</li>
                <li>Datum und Uhrzeit des Zugriffs durch den Besucher,</li>
                <li>Name und URL der vom Besucher aufgerufenen Seite,</li>
                <li>
                    Webseite, von der aus der Besucher auf die Kanzleiwebseite
                    gelangt (sog. Referrer-URL),
                </li>
                <li>
                    Browser und Betriebssystem des Endgeräts des Besuchers sowie
                    der Name des vom Besucher verwendeten Access-Providers.
                </li>
            </ul>
            <p>
                Die Verarbeitung dieser personenbezogenen Daten ist gem. Art. 6
                Abs. 1 Satz 1 Buchst. f) DSGVO gerechtfertigt. Die Kanzlei hat
                ein berechtigtes Interesse an der Datenverarbeitung zu dem
                Zweck,
            </p>
            <ul>
                <li>
                    {" "}
                    die Verbindung zur Webseite der Kanzlei zügig aufzubauen,
                </li>
                <li>
                    eine nutzerfreundliche Anwendung der Webseite zu
                    ermöglichen,
                </li>
                <li>
                    die Sicherheit und Stabilität der Systeme zu erkennen und zu
                    gewährleisten und
                </li>
                <li>
                    die Administration der Webseite zu erleichtern und zu
                    verbessern.
                </li>
            </ul>
            <p>
                Die Verarbeitung erfolgt ausdrücklich nicht zu dem Zweck,
                Erkenntnisse über die Person des Besuchers der Webseite zu
                gewinnen.
            </p>

            <h3>2.2 Newsletter</h3>
            <p>
                {" "}
                Mit der Anmeldung zum Newsletterbezug erklärt sich der Besucher
                ausdrücklich mit der Verarbeitung der übermittelten
                personenbezogenen Daten einverstanden. Für die Anmeldung zum
                Bezug der Newsletter braucht lediglich eine E-Mail-Adresse des
                Besuchers eingetragen werden. Rechtsgrundlage für die
                Verarbeitung der personenbezogenen Daten des Besuchers zum
                Zwecke der Versendung von Newslettern ist die Einwilligung gem.
                Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO.
            </p>
            <p>
                Der Besucher kann sich jederzeit vom Empfang zukünftiger
                Newsletter abmelden. Dies kann über die Verwendung eines
                speziellen Links am Ende des Newsletters oder durch entspre
            </p>
            <p>
                Der Besucher kann sich jederzeit vom Empfang zukünftiger
                Newsletter abmelden. Dies kann über die Verwendung eines
                speziellen Links am Ende des Newsletters oder durch
                entsprechende Nachricht per E-Mail an <MailLink to="info" />{" "}
                erfolgen.
            </p>
            <h2>3. Weitergabe von Daten</h2>
            <p>Personenbezogene Daten werden an Dritte übermittelt, wenn</p>
            <ul>
                <li>
                    nach Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO durch die
                    betroffene Person ausdrücklich dazu eingewilligt wurde,
                </li>
                <li>
                    die Weitergabe nach Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO
                    zur Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen erforderlich ist und kein Grund zur Annahme
                    besteht, dass die betroffene Person ein überwiegendes
                    schutzwürdiges Interesse an der Nichtweitergabe ihrer Daten
                    hat,
                </li>
                <li>
                    für die Datenübermittlung nach Art. 6 Abs. 1 Satz 1 Buchst.
                    c) DSGVO eine gesetzliche Verpflichtung besteht, und/oder
                </li>
                <li>
                    dies nach Art. 6 Abs. 1 Satz 1 Buchst. b) DSGVO für die
                    Erfüllung eines Vertragsverhältnisses mit der betroffenen
                    Person erforderlich ist.
                </li>
            </ul>
            <p>
                In anderen Fällen werden personenbezogene Daten nicht an Dritte
                weitergegeben.
            </p>
            <h2>4. Cookies</h2>
            <p>
                Auf der Webseite werden sog. Cookies eingesetzt. Das sind
                Datenpakete, die zwischen dem Server der Kanzleiwebseite und dem
                Browser des Besuchers ausgetauscht werden. Diese werden beim
                Besuch der Webseite von den jeweils verwendeten Geräten (PC,
                Notebook, Tablet, Smartphone etc.) gespeichert. Cookies können
                insoweit keine Schäden auf den verwendeten Geräten anrichten.
                Insbesondere enthalten sie keine Viren oder sonstige
                Schadsoftware. In den Cookies werden Informationen abgelegt, die
                sich jeweils im Zusammenhang mit dem spezifisch eingesetzten
                Endgerät ergeben. Die Kanzlei kann damit keinesfalls unmittelbar
                Kenntnis von der Identität des Besuchers der Webseite erhalten.
            </p>
            <p>
                Cookies werden nach den Grundeinstellungen der Browser
                größtenteils akzeptiert. Die Browsereinstellungen können so
                eingerichtet werden, dass Cookies entweder auf den verwendeten
                Geräten nicht akzeptiert werden, oder dass jeweils ein
                besonderer Hinweis erfolgt, bevor ein neuer Cookie angelegt
                wird. Es wird allerdings darauf hingewiesen, dass die
                Deaktivierung von Cookies dazu führen kann, dass nicht alle
                Funktionen der Webseite bestmöglich genutzt werden können.
            </p>
            <p>
                Der Einsatz von Cookies dient dazu, die Nutzung des Webangebots
                der Kanzlei komfortabler zu gestalten. So kann beispielsweise
                anhand von Session-Cookies nachvollzogen werden, ob der Besucher
                einzelne Seiten der Webseite bereits besucht hat. Nach Verlassen
                der Webseite werden diese Session-Cookies automatisch gelöscht.
            </p>
            <p>
                Zur Verbesserung der Benutzerfreundlichkeit werden temporäre
                Cookies eingesetzt. Sie werden für einen vorübergehenden
                Zeitraum auf dem Gerät des Besuchers gespeichert. Bei erneutem
                Besuch der Webseite wird automatisch erkannt, dass der Besucher
                die Seite bereits zu einem früheren Zeitpunkt aufgerufen hat und
                welche Eingaben und Einstellungen dabei vorgenommen wurden, um
                diese nicht wiederholen zu müssen.
            </p>
            <p>
                Der Einsatz von Cookies erfolgt außerdem, um die Aufrufe der
                Webseite zu statistischen Zwecken und zum Zwecke der
                Verbesserung des Angebotes zu analysieren. Diese Cookies
                ermöglichen es, bei einem erneuten Besuch automatisch zu
                erkennen, dass die Webseite bereits zuvor vom Besucher
                aufgerufen wurde. Hier erfolgt nach einer jeweils festgelegten
                Zeit eine automatische Löschung der Cookies.
            </p>

            <p>
                Die durch Cookies verarbeiteten Daten sind für die o. g. Zwecke
                zur Wahrung der berechtigten Interessen der Kanzlei nach Art. 6
                Abs. 1 Satz 1 Buchst. f) DSGVO gerechtfertigt.
            </p>
            <h2>5. Analyse-Dienste für Webseiten, Tracking</h2>
            <p>
                Wir nutzen auf unserer Webseite den Webseiten-Analysedienst für
                Webseiten von Google Analytics.
            </p>
            <p>
                Rechtsgrundlage für die Verwendung der Analyse-Tools ist Art. 6
                Abs. 1 Satz 1 Buchst. f) DSGVO. Die Webseitenanalyse liegt im
                berechtigten Interesse unserer Kanzlei und dient der
                statistischen Erfassung der Seitennutzung zur fortlaufenden
                Verbesserung unserer Kanzleiwebseite und des Angebots unserer
                Dienstleistungen.
            </p>
            <h2>7. Ihre Rechte als betroffene Person</h2>
            <p>
                Soweit Ihre personenbezogenen Daten anlässlich des Besuchs
                unserer Webseite verarbeitet werden, stehen Ihnen als
                „betroffene Person“ im Sinne der DSGVO folgende Rechte zu:
            </p>
            <h3>7.1 Auskunft</h3>
            <p>
                Sie können von uns Auskunft darüber verlangen, ob
                personenbezogene Daten von Ihnen bei uns verarbeitet werden.
                Kein Auskunftsrecht besteht, wenn die Erteilung der begehrten
                Informationen gegen die Verschwiegenheitspflicht gem. § 57
                Absatz 1 StBerG verstoßen würde oder die Informationen aus
                sonstigen Gründen, insbesondere wegen eines überwiegenden
                berechtigten Interesses eines Dritten, geheim gehalten werden
                müssen. Hiervon abweichend kann eine Pflicht zur Erteilung der
                Auskunft bestehen, wenn insbesondere unter Berücksichtigung
                drohender Schäden Ihre Interessen gegenüber dem
                Geheimhaltungsinteresse überwiegen. Das Auskunftsrecht ist
                ferner ausgeschlossen, wenn die Daten nur deshalb gespeichert
                sind, weil sie aufgrund gesetzlicher oder satzungsmäßiger
                Aufbewahrungsfristen nicht gelöscht werden dürfen oder
                ausschließlich Zwecken der Datensicherung oder der
                Datenschutzkontrolle dienen, sofern die Auskunftserteilung einen
                unverhältnismäßig hohen Aufwand erfordern würde und die
                Verarbeitung zu anderen Zwecken durch geeignete technische und
                organisatorische Maßnahmen ausgeschlossen ist. Sofern in Ihrem
                Fall das Auskunftsrecht nicht ausgeschlossen ist und Ihre
                personenbezogenen Daten von uns verarbeitet werden, können Sie
                von uns Auskunft über folgende Informationen verlangen:
            </p>
            <ul>
                <li>Zwecke der Verarbeitung,</li>
                <li>
                    Kategorien der von Ihnen verarbeiteten personenbezogenen
                    Daten,
                </li>
                <li>
                    Empfänger oder Kategorien von Empfängern, gegenüber denen
                    Ihre personenbezogenen Daten offen gelegt werden,
                    insbesondere bei Empfängern in Drittländern,
                </li>
                <li>
                    falls möglich die geplante Dauer, für die Ihre
                    personenbezogenen Daten gespeichert werden oder, falls dies
                    nicht möglich ist, die Kriterien für die Festlegung der
                    Speicherdauer,
                </li>
                <li>
                    das Bestehen eines Rechts auf Berichtigung oder Löschung
                    oder Einschränkung der Verarbeitung der Sie betreffenden
                    personenbezogenen Daten oder eines Widerspruchsrechts gegen
                    diese Verarbeitung,
                </li>
                <li>
                    das Bestehen eines Beschwerderechts bei einer
                    Aufsichtsbehörde für den Datenschutz,
                </li>
                <li>
                    sofern die personenbezogenen Daten nicht bei Ihnen als
                    betroffene Person erhoben worden sind, die verfügbaren
                    Informationen über die Datenherkunft,
                </li>
                <li>
                    ggf. das Bestehen einer automatisierten Entscheidungsfindung
                    einschließlich Profiling und aussagekräftige Informationen
                    über die involvierte Logik sowie die Tragweite und
                    angestrebten Auswirkungen automatisierter
                    Entscheidungsfindungen,
                </li>
                <li>
                    ggf. im Fall der Übermittlung an Empfänger in Drittländern,
                    sofern kein Beschluss der EU-Kommission über die
                    Angemessenheit des Schutzniveaus nach Art. 45 Abs. 3 DSGVO
                    vorliegt, Informationen darüber, welche geeigneten Garantien
                    gem. Art. 46 Abs. 2 DSGVO zum Schutze der personenbezogenen
                    Daten vorgesehen sind.
                </li>
            </ul>
            <h3>7.2 Berichtigung und Vervollständigung</h3>
            <p>
                Sofern Sie feststellen, dass uns unrichtige personenbezogene
                Daten von Ihnen vorliegen, können Sie von uns die unverzügliche
                Berichtigung dieser unrichtigen Daten verlangen. Bei
                unvollständigen Sie betreffenden personenbezogenen Daten können
                sie die Vervollständigung verlangen.
            </p>
            <h3>7.3 Löschung</h3>
            <p>
                Sie haben ein Recht auf Löschung ("Recht auf Vergessenwerden"),
                sofern die Verarbeitung nicht zur Ausübung des Rechts auf freie
                Meinungsäußerung, des Rechts auf Information oder zur Erfüllung
                einer rechtlichen Verpflichtung oder zur Wahrnehmung einer
                Aufgabe, die im öffentlichen Interesse liegt, erforderlich ist
                und einer der nachstehenden Gründe zutrifft:
            </p>
            <ul>
                <li>
                    Die personenbezogenen Daten sind für die Zwecke, für die sie
                    verarbeitet wurden, nicht mehr notwendig.
                </li>
                <li>
                    Die Rechtfertigungsgrundlage für die Verarbeitung war
                    ausschließlich Ihre Einwilligung, welche Sie widerrufen
                    haben.
                </li>
                <li>
                    Sie haben Widerspruch gegen die Verarbeitung Ihrer
                    personenbezogenen Daten eingelegt, die wir öffentlich
                    gemacht haben.
                </li>
                <li>
                    Sie haben Widerspruch gegen die Verarbeitung von uns nicht
                    öffentlich gemachter personenbezogener Daten eingelegt und
                    es liegen keine vorrangigen berechtigten Gründe für die
                    Verarbeitung vor.
                </li>
                <li>
                    Ihre personenbezogenen Daten wurden unrechtmäßig
                    verarbeitet.
                </li>
                <li>
                    Die Löschung der personenbezogenen Daten ist zur Erfüllung
                    einer gesetzlichen Verpflichtung, der wir unterliegen,
                    erforderlich.
                </li>
            </ul>
            <p>
                Kein Anspruch auf Löschung besteht, wenn die Löschung im Falle
                rechtmäßiger nicht automatisierter Datenverarbeitung wegen der
                besonderen Art der Speicherung nicht oder nur mit
                unverhältnismäßig hohem Aufwand möglich und Ihr Interesse an der
                Löschung gering ist. In diesem Fall tritt an die Stelle einer
                Löschung die Einschränkung der Verarbeitung.
            </p>
            <h3>7.4 Einschränkung der Verarbeitung</h3>
            <p>
                Sie können von uns die Einschränkung der Verarbeitung verlangen,
                wenn einer der nachstehenden Gründe zutrifft:
            </p>
            <ul>
                <li>
                    Sie bestreiten die Richtigkeit der personenbezogenen Daten.
                    Die Einschränkung kann in diesem Fall für die Dauer verlangt
                    werden, die es uns ermöglicht, die Richtigkeit der Daten zu
                    überprüfen.
                </li>
                <li>
                    Die Verarbeitung ist unrechtmäßig und Sie verlangen statt
                    Löschung die Einschränkung der Nutzung Ihrer
                    personenbezogenen Daten.
                </li>
                <li>
                    Ihre personenbezogenen Daten werden von uns nicht länger für
                    die Zwecke der Verarbeitung benötigt, die Sie jedoch zur
                    Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen benötigen.
                </li>
                <li>
                    Sie haben Widerspruch gem. Art. 21 Abs. 1 DSGVO eingelegt.
                    Die Einschränkung der Verarbeitung kann solange verlangt
                    werden, wie noch nicht feststeht, ob unsere berechtigten
                    Gründe gegenüber Ihren Gründen überwiegen.
                </li>
            </ul>
            <p>
                Einschränkung der Verarbeitung bedeutet, dass die
                personenbezogenen Daten nur mit Ihrer Einwilligung oder zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                oder zum Schutz der Rechte einer anderen natürlichen oder
                juristischen Person oder aus Gründen eines wichtigen
                öffentlichen Interesses verarbeitet werden. Bevor wir die
                Einschränkung aufheben, haben wir die Pflicht, Sie darüber zu
                unterrichten.
            </p>
            <h3>7.5 Datenübertragbarkeit</h3>
            <p>
                Sie haben ein Recht auf Datenübertragbarkeit, sofern die
                Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 Satz 1
                Buchst. a) oder Art. 9 Abs. 2 Buchst. a) DSGVO) oder auf einem
                Vertrag beruht, dessen Vertragspartei Sie sind und die
                Verarbeitung mithilfe automatisierter Verfahren erfolgt. Das
                Recht auf Datenübertragbarkeit beinhaltet in diesem Fall
                folgende Rechte, sofern hierdurch nicht die Rechte und
                Freiheiten anderer Personen beeinträchtigt werden: Sie können
                von uns verlangen, die personenbezogenen Daten, die Sie uns
                bereit gestellt haben, in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten. Sie haben das Recht, diese
                Daten einem anderen Verantwortlichen ohne Behinderung unserseits
                zu übermitteln. Soweit technisch machbar, können Sie von uns
                verlangen, dass wir Ihre personenbezogenen Daten direkt an einen
                anderen Verantwortlichen übermitteln.
            </p>
            <h3>7.6 Widerspruch</h3>
            <p>
                Sofern die Verarbeitung auf Art. 6 Abs. 1 Satz 1 Buchst. e)
                DSGVO (Wahrnehmung einer Aufgabe im öffentlichen Interesse oder
                in Ausübung öffentlicher Gewalt) oder auf Art. 6 Abs. 1 Satz 1
                Buchst. f) DSGVO (berechtigtes Interesse des Verantwortlichen
                oder eines Dritten) beruht, haben Sie das Recht, aus Gründen,
                die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
                die Verarbeitung der Sie betreffenden personenbezogenen Daten
                Widerspruch einzulegen. Das gilt auch für ein auf Art. 6 Abs. 1
                Satz 1 Buchst. e) oder Buchst. f) DSGVO gestütztes Profiling.
                Nach Ausübung des Widerspruchsrechts verarbeiten wir Ihre
                personenbezogenen Daten nicht mehr, es sei denn, wir können
                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen,
                die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
                Verarbeitung dient der Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen.
            </p>
            <p>
                Sie können jederzeit Widerspruch gegen die Verarbeitung der Sie
                betreffenden personenbezogenen Daten zu Zwecken der
                Direktwerbung einlegen. Das gilt auch für ein Profiling, das mit
                einer solchen Direktwerbung in Verbindung steht. Nach Ausübung
                dieses Widerspruchsrechts werden wir die betreffenden
                personenbezogenen Daten nicht mehr für Zwecke der Direktwerbung
                verwenden.
            </p>
            <p>
                Sie haben die Möglichkeit, den Widerspruch telefonisch, per
                E-Mail, ggf. per Telefax oder an unsere zu Beginn dieser
                Datenschutzerklärung aufgeführte Postadresse unserer Kanzlei
                formlos mitzuteilen.
            </p>
            <h3>7.7 Widerruf einer Einwilligung</h3>
            <p>
                Sie haben das Recht, eine erteilte Einwilligung jederzeit mit
                Wirkung für die Zukunft zu widerrufen. Der Widerruf der
                Einwilligung kann telefonisch, per E-Mail, ggf. per Telefax oder
                an unsere Postadresse formlos mitgeteilt werden. Durch den
                Widerruf wird die Rechtmäßigkeit der Datenverarbeitung, die
                aufgrund der Einwilligung bis zum Eingang des Widerrufs erfolgt
                ist, nicht berührt. Nach Eingang des Widerrufs wird die
                Datenverarbeitung, die ausschließlich auf Ihrer Einwilligung
                beruhte, eingestellt.
            </p>
            <h3>7.8 Beschwerde</h3>
            <p>
                Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                betreffenden personenbezogenen Daten rechtswidrig ist, können
                Sie Beschwerde bei einer Aufsichtsbehörde für den Datenschutz
                einlegen, die für den Ort Ihres Aufenthaltes oder Arbeitsplatzes
                oder für den Ort des mutmaßlichen Verstoßes zuständig ist.
            </p>
            <h2>8. Stand und Aktualisierung dieser Datenschutzerklärung</h2>
            <p>
                Diese Datenschutzerklärung hat den Stand vom 01. Januar 2019.
                Wir behalten uns vor, die Datenschutzerklärung zu gegebener Zeit
                zu aktualisieren, um den Datenschutz zu verbessern und/oder an
                geänderte Behördenpraxis oder Rechtsprechung anzupassen.
            </p>
        </div>
        <CtaBanner
            image={data.ctaHat.childImageSharp.fixed}
            alt="Hund mit Partyhut"
            angle={85}
            color="#28b5dc"
            offset={105}
            slogan="Schön dass Sie bis hier gelesen haben."
            ctaType="mail"
        />
    </React.Fragment>
);

export const query = graphql`
    query {
        ctaHat: file(
            relativePath: { eq: "delaney-dawson-130928-unsplash.jpg" }
        ) {
            childImageSharp {
                fixed(height: 420) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
